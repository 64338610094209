import React from "react";
import Navbar from "../../components/Products/nav/Navbar";
import Background from "../../components/Products/background/BackGround";
import Button from "../../components/buttons/Button";
import {
  HeroSection,
  CommonTitle,
  CommonButtonGroup,
  GlobalContent,
  HeroContent,
  Graphic,
  WizardSection,
  HeroContainer,
  Santa,
} from "./style";
import {SectionLayoutColumns} from "../productThree/style";
import LayoutColumn from "../../components/Products/layoutColumn/LayoutColumn";
import Footer from "../../components/footer/Footer";

import MetisLogo from "../../assets/customer-logo/logoMetis.svg";
import {ReactComponent as Busssiness} from "../../assets/customer-logo/bussiness.svg";
import {ReactComponent as Action} from "../../assets/customer-logo/action.svg";
import {ReactComponent as Standard} from "../../assets/customer-logo/standard.svg";

import Power from "../../assets/customer-logo/power.svg";
import Walk from "../../assets/customer-logo/walk.svg";
import Important from "../../assets/customer-logo/important.svg";
import map from "../../assets/map.png";
import tech from "../../assets/Cutting-Edge Technology.png";
import Iphone from "../../components/iphone/Iphone";
import labeler from "../../assets/labeler.jpg";
const Hero = () => {
  return (
    <HeroSection className="container-lg">
      <HeroContainer>
        <HeroContent>
          <CommonTitle className="productTitle">
            Take advantage of the most cutting edge R&D with our
            <em className="u-highlight">business intelligence tools</em>
          </CommonTitle>
          <CommonButtonGroup>
            <li>
              <Button
                href="/apidocs"
                target="_blank"
                bg="#256dfe"
                color="#fff"
              >
                Sign in to Metis
              </Button>
            </li>
            <li>
              <Button color="#000" href={"/contact-us"}>
                learn more
              </Button>
            </li>
          </CommonButtonGroup>
        </HeroContent>
        <Graphic>
          <img src={tech} alt="tech" />
        </Graphic>
      </HeroContainer>
      <Background />
    </HeroSection>
  );
};

const SectionIntro = () => {
  return (
    <section>
      <div className="kimIntro">
        <div className="kimIntro_icon">
          <span className="helm-logo helm-large helm-circle">
            <Busssiness />
          </span>
        </div>
        <div className="kimIntro_title">
          <h2 className="commonTitle kim_title">
            Business Intelligence tools to boost your business
          </h2>
        </div>
        <div className="kimIntro_text">
          <p className="commmonIntroText kim_introText">
            Eulerity Metis allows you to analyze, dissect, and strategize
            according to your competition. Control marketing expenses, reduce
            operational burden, and even create new lines of revenue.
          </p>
        </div>
      </div>
      <SectionLayoutColumns className="layoutColumns--3">
        <LayoutColumn
          icon={Important}
          title="Get important data instantly "
          text="Activate Metis right from the Dashboard and launch your competitive analysis instantly. There are no setup fees or long-term contracts."
        />
        <LayoutColumn
          icon={Power}
          title="Powers our central platforms"
          text="Understand competition on behalf of your network with Metis. We’ll perform the analytics, provide the walk in customer count, and enable users to set custom controls."
        />
        <LayoutColumn
          icon={Walk}
          title="Count Walk In Customers"
          text="Our state of the art partnerships enable us to deploy privacy-safe hardware and count your walk in customers. This gives highly accurate measures of foot traffic data over any other probabalistic method."
        />
      </SectionLayoutColumns>
    </section>
  );
};

const WizardClock = () => {
  return (
    <div className="wizardClock" style={{marginTop: "80px"}}>
      <div className="helm-layoutColumns helm-Layoutcolumn--2">
        <div className="helm-layoutColumns_column">
          <Iphone image={labeler} />
        </div>
        <div className="helm-layoutColumns_column">
          <div className="helm-featureDetail">
            <div className="helm-featureDetail__content">
              <div className="helm-featureDetail__title">
                <h1 className="common-UppercaseTitle commonBodyTitle-slate1">
                  FOOT TRAFFIC ANALYZER
                </h1>
              </div>
              <div className="helm-featureDetail__body">
                <p className="commonBodyText">
                  Accurately analyze and count foot traffic by utilizing
                  Eulerity’s hardware integrations with Wyze and Density. This
                  data is not statistical or probability based but is based on
                  real customers walking through the door. Use this data to see
                  correlation of your marketing efforts to real customers
                  walking through the door.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const WizardDemo = () => {
  return (
    <div className="wizardDemon" style={{marginTop: "80px"}}>
      <div className="helm-layoutColumns helm-Layoutcolumn--2">
        <div className="helm-layoutColumns_column">
          <div className="helm-featureDetail">
            <div className="helm-featureDetail__content">
              <div className="helm-featureDetail__title">
                <h1 className="common-UppercaseTitle commonBodyTitle-slate1">
                  Competition Analyzer
                </h1>
              </div>
              <div className="helm-featureDetail__body">
                <p className="commonBodyText">
                  To provide exhaustive analysis of competition and keep you a
                  step ahead, Eulerity puts you directly in the driver seat,
                  allowing you to make real-time decisions on your marketing
                  strategy. Knowing your competitors offerings can help make
                  your own marketing stand out. Eulerity's business intelligence
                  tools enable your team to set budgets competitively and stay
                  ahead of your peers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="helm-layoutColumns_column">
          <div className="wizardDemo">
            <div className="wizardDemo__content">
              <img src={map} alt="map" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionWizard = () => {
  return (
    <WizardSection>
      <div className="kimIntro">
        <div className="kimIntro_icon">
          <span className="helm-logo helm-large helm-circle ">
            <Action />
          </span>
        </div>
        <div className="kimIntro_title">
          <h2 className="commonTitle kim_title">
            Get actionable data in seconds
          </h2>
        </div>
        <div className="kimIntro_text">
          <p className="commmonIntroText kim_introText">
            With our advanced reporting controls and real-time data streams, you
            have complete analytical capabilities to always be a step ahead from
            your competition.
          </p>
        </div>
      </div>
      <WizardDemo />
      <WizardClock />
    </WizardSection>
  );
};

const FooterSection = () => {
  return (
    <footer className="globalFooter">
      <article className="helm-sectionCTA">
        <div className="helm-footerCta_container container-lg">
          <div className="helm-footerCta__copy">
            <h1 className="commmonIntroText copy_bleue Helm-FooterCTA__title">
              Ready to get started?
            </h1>
            <p>
              Talk to an expert to get the latest updates on how Eulerity
              products is transforming marketing.
            </p>
          </div>
          <CommonButtonGroup>
            <Button
              bg="#fff"
              color="#000"
              href={"/apidocs"}
            >
              Start Now
            </Button>
            <Button bg="#256DFE" color="#fff" href={"/contact-us"}>
              Contact Sales
            </Button>
          </CommonButtonGroup>
        </div>
      </article>

      <Footer />
    </footer>
  );
};

const Pricing = () => {
  return (
    <Santa>
      <div className="container-lg">
        <div className="kimIntro">
          <div className="kimIntro_title">
            <h2 className="commonTitle kim_title">Simplified Solution</h2>
          </div>
          <div className="kimIntro_text">
            <p className="commmonIntroText kim_introText">
              Everything you need to create an intelligent marketing automation
              program. No setup or hidden fees.
            </p>
          </div>
        </div>

        <div className="santa__sled">
          <div className="santa__icon">
            <span className="helm-logo helm-large helm-circle ">
              <Standard />
            </span>
          </div>
          <div className="santa__details">
            <h2 className="santa__title common-UppercaseTitle">STANDARD</h2>
            <div className="santa__row">
              <div className="santa__copy">
                <h3 className="common-BodyTitle">COMPETITION ANALYZER</h3>
                <p className="common-BodyText">
                  Understand your target area and the competition surrounding
                  it.
                </p>
                <ul>
                  <li className="commonBodyText">Location counts by region</li>
                  <li className="commonBodyText">
                    Multiple Data Export Options
                  </li>
                  <li className="commonBodyText">Analyze by industry</li>
                  <li className="commonBodyText">
                    Dynamic controls on spending
                  </li>
                </ul>
              </div>
              <div className="santa__pricing">
                <h4
                  className="santa_price"
                  style={{color: "#F5C15C", textDecoration: "none"}}
                >
                  Special Promotional Pricing
                </h4>
                <p className="santa__priceLabel commonBodyText">
                  Please contact your Eulerity representative for more details.
                </p>
              </div>
            </div>
            <div className="santa__row">
              <div className="santa__copy">
                <h3 className="common-BodyTitle">FOOT TRAFFIC CALCULATOR</h3>
                <p className="common-BodyText">
                  Understand how many people walk through the door
                </p>
                <ul>
                  <li className="commonBodyText">Real time Analysis</li>
                  <li className="commonBodyText">Data Stream Options</li>
                  <li className="commonBodyText">
                    Analyze your peak and slow hours
                  </li>
                  <li className="commonBodyText">
                    Understand how strategies need to be adapted
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Santa>
  );
};
const ProductFour = () => {
  return (
    <>
      <Navbar name="Metis" icon={MetisLogo} />
      <GlobalContent>
        <main>
          <Hero />
          <SectionIntro />
          <SectionWizard />
          <Pricing />
        </main>
      </GlobalContent>
      <FooterSection />
    </>
  );
};

export default ProductFour;

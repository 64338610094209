import React, {useEffect} from "react";
import Button from "../../../components/buttons/Button";
import Global from "../../../components/utilities/SectionGlobe";
import Footer from "../../../components/footer/Footer";
import LogoApp from "../../../assets/customer-logo/friction.svg";
import fccApp from "../../../assets/customer-logo/gears.svg";
import franchiseImg from "../../../assets/fortheretailpage.png";

import {loadScript} from "../../../helperFunction/helper";
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyFooter,
  CopyFooterContainer,
  CopyHeader,
  SectionStarted,
  CopyBodySubText,
  Graphic,
  CopySubtitle,
  CopyBody,
  CtaButton,
  Main,
  Gradient,
} from "../industry/style";
import TableTwo from "../../../components/table/Tabletwo";
const Hero = () => {
  useEffect(() => {
    loadScript("/js/gradient.js");
  }, []);
  return (
    <Section className="Section angleBottom angleBoth ProductHeroSection first ">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>
      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <Gradient className="gradient isLoaded">
              <canvas
                id="gradient-canvas"
                className="Gradient__canvas isLoaded"
              ></canvas>
            </Gradient>

            <ColumnLayout data-columns="2,2">
              <Graphic className="franchise-image-app">
                <img src={franchiseImg} alt="franchise" height="100%" />
              </Graphic>
              <Copy className="variant--Section">
                <CopyHeader>
                  <CopyTitle>
                    Reach all of your merchants with a shopper marketing
                    infrastructure
                  </CopyTitle>
                </CopyHeader>
                <CopyBodySubText className="learn">
                  Eulerity’s white label platform automates retailer internet
                  advertising options for merchants small and large. With our
                  easy to use software, retailers can offer a full self service
                  app platform to sell their own inventory + inventory
                  extensions across the Internet.
                </CopyBodySubText>

                <CopyFooter>
                  <CopyFooterContainer>
                    <Button bg="#256DFE" color="#fff" href={"/contact-us"}>
                      Learn more
                    </Button>
                  </CopyFooterContainer>
                </CopyFooter>
              </Copy>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Payouts = () => {
  return (
    <Section className="Section mtop">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <ColumnLayout data-columns="2,2">
              <RowLayout>
                <Copy className="variant--Section">
                  <CopyHeader>
                    <CopyTitle>
                      Drive incremental revenue from targeted shoppers
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    Meet shoppers where they are & where they visit. Enable your
                    merchants maximum reach to reach loyal customers when they
                    are in purchase mode.
                    <br />
                    <br />
                    Maximize your valuable shopper data both on your own
                    advertising network and off. <br />
                    <br />
                    Your merchants shouldn’t have to wait to do business with
                    you. Enable marketing automation using the world’s most
                    modern self serve advertising platform.
                  </CopyBodySubText>
                </Copy>
              </RowLayout>
              <Graphic className="image-cost"></Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Started = () => {
  return (
    <SectionStarted className="Section top anglebottom ">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className="smallPadding">
            <ColumnLayout data-columns="3,1">
              <Copy className="variant-footer">
                <CopyHeader>
                  <CopyTitle>
                    Ready to get started and activate new suppliers?
                  </CopyTitle>
                </CopyHeader>
                <CopyBody>
                  Schedule a demo and see the future of marketing technology.
                  Whether you end up using our out of the box or enterprise
                  product, you will minimize manual marketing and charge up your
                  strategy.
                </CopyBody>
                <CopyFooter>
                  <CopyFooterContainer>
                    <CtaButton
                      className="cta variant-link"
                      target="_blank"
                      href="/apidocs"
                    >
                      Sign In
                    </CtaButton>
                    <CtaButton
                      className=" variant_button cta cta-arrow"
                      href={"/contact-us"}
                    >
                      Contact sales &rarr;
                    </CtaButton>
                  </CopyFooterContainer>
                </CopyFooter>
              </Copy>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </SectionStarted>
  );
};

const Automated = () => {
  return (
    <Section className="Section">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className="smallPadding">
            <RowLayout>
              <Copy className="variant--Section">
                <CopyHeader>
                  <CopyTitle>
                    Offer Self Serve Omnichannel Marketing Today
                  </CopyTitle>
                </CopyHeader>
              </Copy>
              <ColumnLayout data-columns="1,3">
                <Copy>
                  <CopyHeader>
                    <CopySubtitle>
                      Drive profitable advertising growth
                    </CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText className="drive">
                    Eulerity’s platform enables the buying, creative,
                    optimization, and reporting to thousands of merchants with
                    one single easy to use tool.
                  </CopyBodySubText>
                </Copy>
                <TableTwo />
              </ColumnLayout>
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const BigCards = () => {
  return (
    <Section className="Section">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className="smallPadding">
            <ColumnLayout data-columns="2,2">
              <RowLayout>
                <div className="testimonialsCard">
                  <div className="Card-1">
                    <div className="testimonialsContent">
                      <div className="testimonialsBody">
                        <h1>Your App</h1>
                        <p>
                          Give your suppliers the ability to omnichannel
                          inventory matched with your valuable shopping data,
                          all through an easy to use self service app. Your
                          suppliers download, insert their payment instrument,
                          and activate.
                        </p>
                      </div>
                      <div className="testimoniallogoContainer">
                        <img src={LogoApp} className="UserLogo" alt="logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </RowLayout>

              <RowLayout>
                <div className="testimonialsCard">
                  <div className="Card-2 ">
                    <div className="testimonialsContent">
                      <div className="testimonialsBody">
                        <h1>Your command center</h1>
                        <p>
                          Control the experience your suppliers experience. From
                          creative placements, data options, or real time
                          reporting, maintain full brand control while you
                          enable your customers a seemless media buying
                          experience.
                        </p>
                      </div>
                      <div className="testimoniallogoContainer">
                        <img
                          src={fccApp}
                          className="UserLogo"
                          alt="fccc logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </RowLayout>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};
const Retail = () => {
  return (
    <Main>
      <Hero />
      <Payouts />
      <Automated />
      <Global
        title="Scale your customer count within a week"
        paraFirst="
        Activate ad spend from your mid to long tail merchants globally through one single integration. "
        paraSecond="Give your inventory the diversification it deserves without the high cost of service."
      />
      <BigCards />
      <Started />
      <Footer />
    </Main>
  );
};

export default Retail;

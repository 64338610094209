import React from "react";
import "./style.css";
import Logo from "../../assets/eulerity-logo.png";
import useScroll from "../../hooks/useScrollYHooks";
import {slide as Menu} from "react-burger-menu";

const MenuHamburger = () => {
  const [isShow, toggleMenu] = useScroll(window.scrollY);
  return (
    <div className={`${isShow > 570 ? "show" : ""}`}>
      <Menu
        onStateChange={toggleMenu}
        className="boxMenu"
        width="90%"
        noOverlay
      >
        <div className="menu-group">
          <a href="/">
            <img src={Logo} alt="Eulerity Logo" />
          </a>
        </div>
        <div className="menu-group">
          <h2>
            <i className="fa fa-cube" /> Products
          </h2>
          <ul>
            <li>
              <a href="/productOne">Panacea</a>
            </li>
            <li>
              <a href="/productTwo">Titan</a>
            </li>
            <li>
              <a href="/productThree">Dolos</a>
            </li>
            <li>
              <a href="/productFour">Metis</a>
            </li>
          </ul>
        </div>
        <div className="menu-group">
          <h2>
            <i className="fa fa-store-alt" /> Use Cases
          </h2>
          <ul>
            <li>
              <a href="/franchise/industry">Franchise</a>
            </li>
            <li>
              <a href="/franchise/enterprise">Enterprise</a>
            </li>
            <li>
              <a href="/franchise/retail">Retail</a>
            </li>
            <li>
              <a href="/automotive">Automotive</a>
            </li>
            <li>
              <a href="/financial-services">Financial Services</a>
            </li>
          </ul>
        </div>
        <div className="menu-group">
          <h2>
            <i className="fa fa-briefcase" /> Company
          </h2>
          <ul>
            <li>
              <a href="/company/about-us">About us</a>
            </li>
            <li>
              <a href="/company/contenthub">Content Hub</a>
            </li>
            <li>
              <a href="/company/case-studies">Case studies</a>
            </li>
            <li>
              <a href="/company/podcasts">Podcasts</a>
            </li>
          </ul>
        </div>
        <div className="menu-group">
          <h2>
          <i class="fa fa-id-badge" aria-hidden="true" /> Careers
          </h2>
          <ul>
            <li>
              <a href="https://boards.greenhouse.io/eulerity" target="_blank">Job Opportunities</a>
            </li>
          </ul>
        </div>
        <div className="menu-group">
          <a className="sbtn" href="/apidocs" target="_blank">
            Sign in
          </a>
        </div>
      </Menu>
    </div>
  );
};

export default MenuHamburger;
